@media (--breakpoint-md-up) {
  main.product {
    display: grid;
    grid-template-columns: [content] 50% [thumbs] 1fr [image] 3fr;
    grid-template-rows: [meta] auto [description] auto [specs] auto;
    grid-gap: var(--spacing-lg);

    .product-meta {
      grid-column: content;
      grid-row: meta;
    }

    .product-description {
      grid-column: content;
      grid-row: description;
    }

    .product-specs {
      grid-column: content;
      grid-row: specs;
    }

    .product-image {
      grid-column: image;
      grid-row: meta / span 3;
      width: 100%;
    }

    .product-enquire {
      grid-column: content;
      grid-row: 4; 
    }

    .product-3d {
      grid-column: image;
      grid-row: meta / span 2;
      align-self: start;
      justify-self: end;
    }
  }
}