.social {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  li {

    a {
      font-size: 1.5rem;
      padding: 0.5rem;

      &:--enter {
        text-decoration: none;
      }
    }
  }
}