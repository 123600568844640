.news-item {
  overflow: hidden;

  &-image {
    display: block;
    object-fit: cover;
    max-height: 10rem;
    margin: 0 calc(-1 * var(--spacing-lg) );
    max-width: 100vw;
    width: calc( 100% + ( var(--spacing-lg) * 2 ));
  }
}