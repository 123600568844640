.youtube {
  
  &-wrapper {
    background: #000;
    position: relative;
    padding-top: 56.25%; /* 16:9 */
    max-width: 100%;
    cursor: pointer;
    overflow: hidden;
  }

  img {
    width: 100%;
    top: -16.84%;
    left: 0;
    opacity: 0.7;
  }

  &-button {
    width: 90px;
    height: 60px;
    background-color: #333;
    box-shadow: 0 0 30px rgba(0,0,0,0.6);
    z-index: 1;
    opacity: 0.8;
    border-radius: 6px;
    transition: background-color 0.2s ease-in-out;

    &:before {
      content: "";
      border-style: solid;
      border-width: 15px 0 15px 26.0px;
      border-color: transparent transparent transparent #fff;
    }

    .youtube-wrapper:--enter & {
      background-color: var(--state-primary);
    }
  }

  img, &-button {
    cursor: pointer;
  }

  img,
  iframe,
  &-button,
  &-button:before {
    position: absolute;
  }

  &-button,
  &-button:before {
    top: 50%;
    left: 50%;
    transform: translate3d( -50%, -50%, 0 );
  }

  iframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (--breakpoint-md-up) {
  .youtube {
    margin: 0 auto;
    max-width: 80%;
  }
}

@media (--breakpoint-lg-up) {
  .youtube {
    margin: 0 auto;
    max-width: 60%;
  }
}