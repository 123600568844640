.d-b { display: block; }
.d-ib { display: inline-block; }
.d-i { display: inline; }
.d-f { display: flex; }

.mar-l-auto { margin-left: auto; }
.mar-r-auto { margin-right: auto; }
.mar-t-auto { margin-top: auto; }
.mar-b-auto { margin-bottom: auto; }

.text-normal { font-weight: var(--font-weight); }
.text-size-1 { font-size: 0.5em; }
.text-size-2 { font-size: 0.75em; }
.text-size-3 { font-size: 1em; }
.text-size-4 { font-size: 1.25em }
.text-size-5 { font-size: 1.5em; }
.text-size-6 { font-size: 2em; }
.text-size-7 { font-size: 3em; }

/* Borders */
.border-none { border-style: none !important; }
.border-solid { border-style: solid !important; }
.border-dashed { border-style: dashed !important; }
.border-dotted { border-style: dotted !important; }

.border-sm { border-width: 1px; }
.border-md { border-width: 2px; }
.border-lg { border-width: 4px; }

.border-transparent { border-color: transparent !important; }
.border-primary { border-color: var(--state-primary) !important; }
.border-secondary { border-color: var(--state-secondary) !important; }
.border-success { border-color: var(--state-success) !important; }
.border-info { border-color: var(--state-info) !important; }
.border-warning { border-color: var(--state-warning) !important; }
.border-danger { border-color: var(--state-danger) !important; }
.border-light { border-color: var(--state-light) !important; }
.border-dark { border-color: var(--state-dark) !important; }

.border-round { border-radius: 0.5rem; }
.border-round-sm { border-radius: 0.25rem; }
.border-round-lg { border-radius: 1rem; }

.bg-transparent { background-color: transparent !important; }
.bg-white { background-color: white !important; }


/* Flex Stuff */
.fxd-r { flex-direction: row; }
.fxd-rr { flex-direction: row-reverse; }
.fxd-c { flex-direction: column; }
.fxd-cr { flex-direction: column-reverse; }

.jc-fs { justify-content: flex-start !important; }
.jc-fe { justify-content: flex-end !important; }
.jc-c { justify-content: center !important; }

.ai-b { align-items:baseline !important; }
.ai-c { align-items:center !important; }
.ai-fe { align-items:flex-end !important; }
.ai-fs { align-items:flex-start !important; }
.ai-s { align-items:stretch !important; }