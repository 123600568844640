.privacy {
  list-style: none;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: var(--font-sans-serif);

  li {
    margin: 0 1rem;

    a {
      padding: 0.5rem 1rem;
    }
  }
}